import Prismic from '@prismicio/client'
import React, {useEffect,useMemo} from 'react'
//import {useAsync} from '../hooks/useAsync'

function PrismicLoader({loaded}){
  return loaded ? <div>Loaded</div> : <div>Loading...</div>
}

export function PrismicAPI({endpoint,loader,children,onLoad}) {
  
  const Client = useMemo(()=>Prismic.client(endpoint),[endpoint]);

  const [state, setState] = React.useState({
    isLoaded : false,
    docs : null
  })

  let {isLoaded,docs} = state;

  useEffect(() => {
  
    if(!isLoaded){

    const fetchDocs = async () => {
      const response = await Client.query('', { pageSize : 100 })
      
      setState({
        docs : response.results,
        isLoaded : true
      });

      if(onLoad && !isLoaded){

        onLoad({
          docs : response.results
        });

      }

    }

    fetchDocs();

    }
    

    // Specify how to clean up after this effect:
    return function cleanup() {
      // Cleanup function
    };
  },[Client,onLoad,isLoaded]);



  let Loader = loader ? loader : PrismicLoader; 

  return <>
    
    <div className={`Prismic-loader Prismic-loader--${isLoaded ? 'loaded' : 'loading'}`}>
      <Loader loaded={isLoaded} />
    </div>

    <div className={`Prismic-content Prismic-content--${isLoaded ? 'loaded' : 'loading'}`}>
      
      {children && children({
        isLoaded : isLoaded,
        client : Client,
        docs : docs
      })}

    </div>

  </>

  //return isLoaded ? 'Loaded' : 'Not loaded';

  //return <div>[[ PRISMIC API {endpoint} ]]</div>
}
