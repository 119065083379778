export const get = function(url, params, cb) {
    send(url, 'GET', params, cb);
}

export const post = function(url, params, cb) {
    send(url, 'POST', params, cb);
}

export const send = function(url, method, params, cb) {
    var xhr = new XMLHttpRequest();
    xhr.open(method, url, true);
    xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) {
            var data = xhr.responseText;
            try {
                data = JSON.parse(data);
            } catch (exc) {
            }
            if (cb) {
                cb(data);
            }
        }
    }

    var body;
    if (params) {
        var bodies = [];
        for (var name in params) {
            bodies.push(name + '=' + encodeURIComponent(params[name]));
        }

        body = bodies.join('&');
        if (body.length) {
            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded"); 
        }        
    }

    xhr.send(body);
}