import React, { useEffect, useState, useRef, memo } from "react";
import { NavLink, Link, useHistory, useLocation } from "react-router-dom";
import { DragWall } from "../components/DragWall.js";
import { shuffle } from "../utils/shuffle.js";
import { useStore, Store, StoreRouter } from "../store";
import { Content } from "../components/Content.js";
import IsVisible from "../components/IsVisible.js";
import LazyImage from "../components/LazyImage.js";
import { FadeSwiper } from "../components/FadeSwiper.js";
import { ScrollOpacity } from "../components/ScrollOpacity.js";
import { GrFormView } from "react-icons/gr";

import { useMount, useWindowSize } from "react-use";
import { get } from "../utils/ajax";
import anime from "animejs";

import { BsGrid3X3, BsCollectionPlay, BsCaretRight } from "react-icons/bs";
import {
  CgMenuGridR,
  CgMicrosoft,
  CgPlayButtonO,
  CgRename,
} from "react-icons/cg";
import {
  IoChevronDownCircleOutline,
  IoPlayCircleOutline,
} from "react-icons/io5";
import { IoMdPlay } from "react-icons/io";

function Media({ item, fit = "cover", lazy = false }) {
  let url = "";
  if (item.media && item.media.url && item.media.url.length) {
    url = item.media.url;
  } else if (item.video_url) {
    // Set url to video url (could be remote mp4 or vimeo embed)
    url = item.video_url;
  }

  if (url.match(".mp4")) {
    return (
      <video
        src={url}
        initial={false}
        autoPlay={true}
        controls={false}
        playsInline={true}
        muted={true}
        loop={true}
      />
    );
  } else if (item.video_url) {
    // Video URL that's not an mp4, embed it...
    return <VideoIframe id={item.video_url} fit={fit} />;
  } else {
    return lazy ? (
      <LazyImage src={`${url.replace("auto=compress,format", "")}`} />
    ) : (
      <img src={`${url.replace("auto=compress,format", "")}`} />
    );
  }
}

function VimeoSize({ id, children }) {
  let [size, setSize] = useState(null);

  useMount(() => {
    get(
      `https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/${id}`,
      {},
      (response) => {
        if (response.hasOwnProperty("width")) {
          setSize([parseInt(response.width), parseInt(response.height)]);
        }
      }
    );
  });

  if (!size) return null;

  return children({ width: size[0], height: size[1] });
}

function VideoIframe({ id, fit }) {
  // eg 274772955
  if (!id) return null;

  let vimeoId = parseInt(id.replace(/https?:\/\/vimeo.com\//, ""));

  return (
    <VimeoSize id={vimeoId}>
      {({ width, height }) => (
        <div data-rel="iframe">
          <FitBox w={width} h={height} mode={fit}>
            <iframe
              src={`https://player.vimeo.com/video/${vimeoId}?title=0&byline=0&portrait=0&color=000000&muted=1&autoplay=1&autopause=0&loop=1&background=1&app_id=122963`}
              width={"100%"}
              height={"100%"}
              frameborder={0}
              allow={"autoplay"}
              allowfullscreen
            ></iframe>
          </FitBox>
        </div>
      )}
    </VimeoSize>
  );
}

function FitBox({ w = 1, h = 1, mode = "cover", children }) {
  let ref = useRef(null);
  let child = useRef(null);
  let [size, setSize] = useState(["100%", "auto"]);

  function resize() {
    // Size & aspect of frame (space to fill)
    let aw = ref.current.offsetWidth;
    let ah = ref.current.offsetHeight;
    let aspect = aw / ah;
    let reverseAspect = ah / aw;

    // Size and aspect of child (object ot fitted)
    let cw = w;
    let ch = h;
    let childAspect = cw / ch;
    let childReverseAspect = ch / cw;

    if (mode === "cover") {
      // Cover mode
      let setW, setH;
      if (aspect < childAspect) {
        // Frame is more portrait
        // Fit to height
        setH = ah;
        setW = ah * childAspect;
      } else {
        // Frame is more landscape
        // Fit to width
        setW = aw;
        setH = aw * childReverseAspect;
      }

      setW = Math.round(setW);
      setH = Math.round(setH);

      setSize([setW, setH]);
    } else {
      // Contain mode...

      let setW, setH;
      if (aspect > childAspect) {
        // Frame is more landscape
        // Fit to height
        setH = ah;
        setW = ah * childAspect;
      } else {
        // Frame is more portrait
        // Fit to width
        setW = aw;
        setH = aw * childReverseAspect;
      }

      setW = Math.round(setW);
      setH = Math.round(setH);

      setSize([setW, setH]);
    }
  }

  useEffect(() => {
    // Add event listener
    window.addEventListener("resize", resize);
    // Call handler right away so state gets updated with initial window size
    resize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", resize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div
      ref={ref}
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        overflow: "hidden",
      }}
    >
      <div
        ref={child}
        style={{
          position: "absolute",
          top: `50%`,
          left: `50%`,
          transform: `translate(-50%,-50%)`,
          width: size[0],
          height: size[1],
        }}
      >
        {children}
      </div>
    </div>
  );
}

function Break({ slice }) {
  return (
    <>
      <div className="Slice--window">
        <div className="Slice Slice--break">
          <div className={"Slice-media"}>
            <div
              className={`Slice-media-frame Slice-media-frame--${slice.primary.size.toLowerCase()}`}
            >
              <Media
                fit={slice.primary.size.toLowerCase()}
                lazy={true}
                item={{
                  media: slice.primary.media,
                  video_url: slice.primary.video_url,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Slice({ slice, ix }) {
  return (
    <IsVisible
      fallback={() => <div style={{ height: "100vh" }} />}
      once={() => {
        switch (slice.slice_type) {
          /*case 'text':
              return <TextSlice slice={slice} />

              case 'media':
              return <MediaSlice slice={slice} />*/

          case "carousel":
            return <Carousel slice={slice} ix={ix} />;

          // video_break
          case "video_break":
            return <Break slice={slice} />;

          default:
            return null;
        }
      }}
    />
  );
}

const LazySlideContents = memo(({ active, children }) => {
  return active ? children : null;
});

function Carousel({ slice, ix }) {
  const [scrubActive, setScrubActive] = useState(false);
  let swiperApi = useRef(false);

  const [curSlide, setCurSlide] = useState(0);

  function isActiveOrNeighbour(ix) {
    const isActive = curSlide === ix;
    const isPrev = curSlide === ix - 1;
    const isNext = curSlide === ix + 1;

    return isActive || isPrev || isNext;
  }

  return (
    <div
      className={`Slice Slice--carousel Slice--${
        !slice.primary.description || !slice.primary.description.length
          ? "fullscreen"
          : "inset"
      }`}
      style={{ background: slice.primary.background }}
    >
      <div className="Slice-body">
        <ScrollOpacity>
          <h2>{slice.primary.heading}</h2>
          <Content content={slice.primary.description} />
        </ScrollOpacity>
      </div>

      {(() => {
        if (slice.items.length > 1) {
          return (
            <div className="Slice-icons">
              <div className="Slice-icon">
                <Link
                  to={`#/gallery/${ix + 1}/`}
                  onMouseEnter={() => setScrubActive(false)}
                >
                  <CgMicrosoft /> <span>Gallery</span>
                </Link>
              </div>

              <div className="Slice-icon">
                <Link to={`#`} onMouseEnter={() => setScrubActive(true)}>
                  <IoMdPlay /> <span>Filmstrip</span>
                </Link>
              </div>

              <div
                className={`Slice-scrub ${scrubActive ? "active" : "inactive"}`}
                onMouseLeave={() => {
                  setScrubActive(false);
                }}
              >
                <div className="Slice-scrub-track">
                  {slice.items.map((i, ix) => (
                    <div
                      onMouseEnter={() => {
                        if (swiperApi.current) {
                          swiperApi.current.slideTo(ix + 1);
                        }
                      }}
                      className="Slice-scrub-thumb"
                      style={{
                        width: `${(1 / slice.items.length) * 100}%`,
                        backgroundImage: `url(${
                          i.filmstrip_thumb.hasOwnProperty("url")
                            ? i.filmstrip_thumb.url
                            : i.media.url
                        }&w=300)`,
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          );
        }
      })()}

      <div className="Slice-media">
        <div
          className="Slice-media-foot"
          onMouseEnter={() => setScrubActive(true)}
        />

        <FadeSwiper
          onInit={({ swiper }) => {
            swiper.on("slideChange", function () {
              setCurSlide(swiper.realIndex);
            });
            swiperApi.current = swiper;
          }}
          slides={slice.items.map((item, s_ix) => (
            <div
              style={{ background: slice.primary.background }}
              className={`Slice-object Slice-object--${item.size.toLowerCase()}`}
            >
              <LazySlideContents active={isActiveOrNeighbour(s_ix)}>
                <Media item={item} fit={item.size.toLowerCase()} />
              </LazySlideContents>
            </div>
          ))}
        />
      </div>
    </div>
  );
}

export const Home = ({ page, profile }) => {
  const { setCursor, unsetCursor } = useStore();
  const scroller = useRef();

  return (
    <div className="Page Page--home">
      <div className="Page-scroll" ref={scroller}>
        <div
          className="Page-reel"
          onClick={() => {
            if (scroller.current) {
              anime({
                targets: scroller.current,
                scrollTop: window.innerHeight,
                duration: 1000,
                easing: "easeOutQuint",
              });
            }
          }}
          onMouseEnter={() => {
            setCursor("down");
          }}
          onMouseLeave={() => {
            unsetCursor();
          }}
        >
          {/*<div className="Page-reel-skip">
              <IoChevronDownCircleOutline />
              </div>*/}
          <div className="Page-reel-media">
            <Media item={{ media: page.reel }} />
          </div>
        </div>

        <div className="Page-sections">
          {page.body.map((slice, s_ix) => (
            <Slice slice={slice} ix={s_ix} key={s_ix} />
          ))}
        </div>
        <div className="Page-profile">
          <div className="Page-paper">
            <div className="Profile">
              <div className="Profile-intro">
                <Content content={profile.body} />
              </div>
              <div className="Profile-contact">
                <Content modifier="contact" content={profile.contact} />
              </div>
              <div className="Profile-aside">
                <div>
                  <Content content={profile.section_1} />
                </div>
                <div>
                  <Content content={profile.section_2} />
                </div>
              </div>
              <h3 className="Page-scroll-title">Index</h3>
            </div>
          </div>
          <div className="Page-horiz-scroll">
            {page.body.map((slice, s_ix) => {
              if (slice.slice_type !== "carousel") return null;
              if (!slice.items.length) return null;
              if (!slice.items[0].hasOwnProperty("media")) return null;
              if (!slice.items[0].media.hasOwnProperty("url")) return null;

              return (
                <div className="Page-thumb">
                  <Link to={`#/gallery/${s_ix + 1}/`}>
                    <LazyImage src={`${slice.items[0].media.url}&w=800`} />

                    <h2>{slice.primary.heading}&nbsp;</h2>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
