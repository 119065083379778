import { useMediaQuery } from "react-responsive";

export const RespondTo = ({ media, children }) => {
	const matchQuery = useMediaQuery({ query: media });
	return matchQuery ? children : null;
};
/*
<RespondTo query={`(max-width: 745px)`}>
  Mobile
</RespondTo>

<RespondTo query={'(orientation: portrait)'}>
  Portrait
</RespondTo>
*/
