import React, { Component } from "react";
import anime from "animejs";

export function ScrollOpacity({ ix, children }) {
  const loop = React.useRef();
  const previousTimeRef = React.useRef();
  const el = React.useRef();
  const styled = React.useRef();
  const opacity = React.useRef();

  const top = window.innerHeight * 0.15;
  const topDist = top * 0.65;

  const bottom = window.innerHeight * 0.5;
  const bottomDist = bottom * 0.65;

  const minOpacity = 0;

  const animate = (time) => {
    if (!el.current) return;
    if (previousTimeRef.current != undefined) {
      let pos = el.current.getBoundingClientRect();
      let o = 0;
      let translate = 0;
      // Above top threshold
      if (pos.top < top) {
        let diff = top - pos.top;
        translate = 10;

        o = 1 - diff / topDist;
        if (o < 0) o = 0;

        // Below bottom threshold
      } else if (pos.top > bottom) {
        let diff = pos.top - bottom;
        o = 1 - diff / bottomDist;
        translate = 0;

        if (o < minOpacity) o = minOpacity;
      } else {
        o = 1;
        translate = 0;
      }

      if (o != opacity.current) {
        opacity.current = o;
        //el.current.style.opacity = o;

        anime.set(styled.current, {
          opacity: o,
          translateY: translate * (1 - o),
          filter: `blur(${4 * (1 - o).toFixed(1)}px)`,
        });
      }
    }

    previousTimeRef.current = time;
    loop.current = requestAnimationFrame(animate);
  };

  React.useEffect(() => {
    loop.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(loop.current);
  }, []); // Make sure the effect runs only once

  return (
    <div ref={el}>
      <div
        ref={styled}
        style={{
          filter: `blur(0)`,
          "will-change": `filter, transform, opacity`,
        }}
      >
        {children}
      </div>
    </div>
  );
}
