import React , {useEffect,useState} from 'react'
import {Tooltip} from "./Tooltip";

import {useBodyClass} from '../hooks/useBodyClass';

import { FaPlay } from "react-icons/fa";
import { GrFormNextLink } from "react-icons/gr";

export const SmartCursor = ({state,active}) => {

    let stateName = `Body--cursor-state-${state}`
    useBodyClass(stateName);

    let activeName = `Body--cursor-${active ? 'active' : 'inactive' }`
    useBodyClass(activeName);
                                                               
   return (
    <Tooltip>
  		<svg xmlns="http://www.w3.org/2000/svg" width="88" height="68" viewBox="0 0 88 68"><path fill-rule="evenodd" d="M81.04 34H88L58 0H46l26.47 30H0v8h72.47L46 68h12l30-34h-6.96z"/></svg>
        {/*<GrFormNextLink />*/}
    </Tooltip>
    )
}
