import React, { useEffect, useState } from "react";
import { NavLink, Link, useHistory, useLocation } from "react-router-dom";

import { useBodyClass } from "../hooks/useBodyClass.js";

import { Presence } from "../components/Presence.js";

function BgMedia({ media }) {
  if (media.url.match(".mp4")) {
    return (
      <video
        src={media.url}
        initial={false}
        autoPlay={true}
        controls={false}
        playsInline={true}
        muted={true}
        loop={true}
      />
    );
  } else {
    return <img src={`${media.url}&w=1200&w=1920&cs=srgb`} />;
  }
}

function Hover({ src, callback = (val) => {}, children }) {
  return (
    <span
      onMouseEnter={() => callback(src)}
      onMouseLeave={() => callback(null)}
    >
      {children}
    </span>
  );
}

export const Index = ({ entry, projects }) => {
  let [tooltipmedia, setTooltip] = React.useState(null);

  return (
    <div className="Page Page--index">
      <Presence isVisible={tooltipmedia}>
        <div className="Page-bg">
          {tooltipmedia && <BgMedia key={tooltipmedia} media={tooltipmedia} />}
        </div>
      </Presence>

      <div className="Page-scroll">
        <div className="Page-paper">
          {projects.map((p, ix) => (
            <div key={ix}>
              {p.uri && (
                <Hover src={p.rich_media} callback={setTooltip}>
                  <Link
                    to={p.uri}
                    onClick={() => {
                      window.activeTile = null;
                    }}
                  >
                    {p.title}
                  </Link>
                </Hover>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
