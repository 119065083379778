import React , {useEffect,useState} from 'react'
import {useStore,Store,StoreRouter} from '../store';
import {  NavLink, Link, useHistory, useLocation} from 'react-router-dom';


import { GrContract,GrFormPreviousLink,GrClose,GrUndo,GrFormClose } from "react-icons/gr";

export const Burger = ({entry}) => {

      const HISTORY = useStore(state => state.HISTORY);
      const ENTRY = useStore(state => state.ENTRY);
      const PROFILE = useStore(state => state.PROFILE);

      let previousListingPage = HISTORY.find(e=>(['profile'].indexOf(e.type) === -1));
      let backTo = previousListingPage ? previousListingPage.href : '/';

      if(ENTRY.id !== PROFILE.id){
        return <Link className="Burger" to={PROFILE.uri}><span className="Burger-text">{PROFILE.title}</span><span className="Burger-el Burger-el--1" /><span className="Burger-el Burger-el--2" /><span className="Burger-el Burger-el--3" /></Link>
      }else{
        return <Link className="Burger" to={backTo}><GrFormClose /></Link>
      }

}


