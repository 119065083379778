import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import { useStore, Store, StoreRouter } from "../store";

import { isTouchDevice } from "../components/IsTouch";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/effect-fade/effect-fade.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";

// import Swiper core and required modules
import SwiperCore, { EffectFade, Navigation, Pagination } from "swiper/core";

// install Swiper modules
SwiperCore.use([EffectFade, Navigation, Pagination]);

export const FadeSwiper = ({ slides, onInit = () => {} }) => {
  const { setCursor, unsetCursor } = useStore();
  const [swiper, setSwiper] = useState(null);

  const cur = useRef(1);
  return (
    <>
      {(() => {
        if (slides.length > 1) {
          return (
            <>
              <div
                onClick={() => {
                  cur.current -= 1;
                  if (cur.current < 1) cur.current = slides.length;
                  swiper.slideTo(cur.current);
                }}
                onMouseEnter={() => {
                  setCursor("prev");
                }}
                onMouseLeave={() => {
                  unsetCursor();
                }}
                className="Swiper-prev"
              ></div>
              <div
                onClick={() => {
                  cur.current += 1;
                  if (cur.current > slides.length) cur.current = 1;
                  swiper.slideTo(cur.current);
                }}
                onMouseEnter={() => {
                  setCursor("next");
                }}
                onMouseLeave={() => {
                  unsetCursor();
                }}
                className="Swiper-next"
              ></div>
            </>
          );
        }
      })()}

      <Swiper
        onSwiper={(api) => {
          setSwiper(api);
          if (onInit) onInit({ swiper: api });
        }}
        spaceBetween={30}
        loop={true}
        effect={isTouchDevice() ? null : "fade"}
        speed={500}
        navigation={false}
        pagination={
          slides.length > 1
            ? {
                clickable: true,
              }
            : false
        }
        className="mySwiper"
      >
        {slides.map((slide, s_ix) => (
          <SwiperSlide key={s_ix}>{slide}</SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};
