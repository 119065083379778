import React, { useEffect, useState, useRef } from "react";
import { useStore, Store, StoreRouter } from "../store";
import { NavLink, Link, useHistory, useLocation } from "react-router-dom";
import { Content } from "../components/Content.js";
import LazyImage from "../components/LazyImage.js";
import Masonry from "react-masonry-css";

import {
  GrContract,
  GrFormPreviousLink,
  GrClose,
  GrUndo,
  GrFormClose,
} from "react-icons/gr";

import { motion, useElementScroll } from "framer-motion";

import { useMount, useWindowSize } from "react-use";
import { get } from "../utils/ajax";

import { useBodyClass } from "../hooks/useBodyClass.js";

function BodyClass({ className }) {
  useBodyClass(className);

  return <div />;
}

function Slice({ slice }) {
  switch (slice.slice_type) {
    case "text":
      return <TextSlice slice={slice} />;

    case "media":
      return <MediaSlice slice={slice} />;

    default:
      return null;
  }
}

function TextSlice({ slice }) {
  return (
    <div className="Blocks">
      <div className={`Block Block--text`}>
        <Content content={slice.primary.text} />
      </div>
    </div>
  );
}

function MediaSlice({ slice }) {
  let style = "full";
  if (slice.primary.style) style = slice.primary.style.toLowerCase();

  return (
    <div className={`Blocks Blocks--${style}`}>
      <div className="Blocks-grid">
        {slice.items.map((i, ix) => (
          <div
            className={`Block Block--media Block--size-${i.size
              .replace(" ", "-")
              .toLowerCase()}`}
          >
            <div className="Block-media">
              <Media item={i} />
            </div>
            <div className="Block-caption">
              <span>{i.caption}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function ActiveTile({ media }) {
  return (
    <div className={`Blocks Blocks--full`}>
      <div className="Blocks-grid">
        <div className={`Block Block--media Block--size-full`}>
          <div className="Block-media">
            <ActiveMedia media={media} />
          </div>
        </div>
        ))}
      </div>
    </div>
  );
}

function ActiveMedia({ media }) {
  if (media.url.match(".mp4")) {
    return (
      <video
        src={media.url}
        initial={false}
        autoPlay={true}
        controls={false}
        playsInline={true}
        muted={true}
        loop={true}
      />
    );
  } else {
    return <img src={`${media.url}&w=1200`} />;
  }
}

function Media({ item }) {
  let url = "";
  if (item.media && item.media.url && item.media.url.length) {
    url = item.media.url;
  } else if (item.video_url) {
    // Set url to video url (could be remote mp4 or vimeo embed)
    url = item.video_url;
  }

  if (url.match(".mp4")) {
    return (
      <div className="Page-ratio">
        <div
          style={{
            paddingTop: `${(item.media.height / item.media.width) * 100}%`,
          }}
        />
        <video
          src={url}
          initial={false}
          autoPlay={true}
          controls={false}
          playsInline={true}
          muted={true}
          loop={true}
        />
      </div>
    );
  } else if (item.video_url) {
    // Video URL that's not an mp4, embed it...

    return (
      <div className="Page-ratio">
        <VideoIframe
          id={item.video_url}
          before={({ width, height }) => {
            return (
              <div
                style={{
                  paddingTop: `${(height / width) * 100}%`,
                }}
              />
            );
          }}
        />
      </div>
    );
  } else {
    return (
      <div className="Page-ratio">
        <div
          style={{
            paddingTop: `${(item.media.height / item.media.width) * 100}%`,
          }}
        />
        <LazyImage src={`${url}&w=600`} />
      </div>
    );
  }
}

/*function VideoIframe({ id }) {
  // eg 274772955
  if (!id) return null;

  let vimeoId = parseInt(id.replace(/https?:\/\/vimeo.com\//, ""));

  return (
    <iframe
      src={`https://player.vimeo.com/video/${vimeoId}?title=0&byline=0&portrait=0&color=000000&muted=1&autoplay=1&autopause=0&loop=1&background=1&app_id=122963`}
      width={1080}
      height={720}
      frameborder={0}
      allow={"autoplay"}
      allowfullscreen
    ></iframe>
  );
}*/

function VimeoSize({ id, children }) {
  let [size, setSize] = useState(null);

  useMount(() => {
    get(
      `https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/${id}`,
      {},
      (response) => {
        if (response.hasOwnProperty("width")) {
          setSize([parseInt(response.width), parseInt(response.height)]);
        }
      }
    );
  });

  if (!size) return null;

  return children({ width: size[0], height: size[1] });
}

function VideoIframe({ id, fit, before = () => null }) {
  // eg 274772955
  if (!id) return null;

  let vimeoId = parseInt(id.replace(/https?:\/\/vimeo.com\//, ""));

  return (
    <VimeoSize id={vimeoId}>
      {({ width, height }) => (
        <div data-rel="iframe">
          {before({ width, height })}
          <FitBox w={width} h={height} mode={fit}>
            <iframe
              src={`https://player.vimeo.com/video/${vimeoId}?title=0&byline=0&portrait=0&color=000000&muted=1&autoplay=1&autopause=0&loop=1&background=1&app_id=122963`}
              width={"100%"}
              height={"100%"}
              frameborder={0}
              allow={"autoplay"}
              allowfullscreen
            ></iframe>
          </FitBox>
        </div>
      )}
    </VimeoSize>
  );
}

function FitBox({ w = 1, h = 1, mode = "cover", children }) {
  let ref = useRef(null);
  let child = useRef(null);
  let [size, setSize] = useState(["100%", "auto"]);

  function resize() {
    // Size & aspect of frame (space to fill)
    let aw = ref.current.offsetWidth;
    let ah = ref.current.offsetHeight;
    let aspect = aw / ah;
    let reverseAspect = ah / aw;

    // Size and aspect of child (object ot fitted)
    let cw = w;
    let ch = h;
    let childAspect = cw / ch;
    let childReverseAspect = ch / cw;

    if (mode === "cover") {
      // Cover mode
      let setW, setH;
      if (aspect < childAspect) {
        // Frame is more portrait
        // Fit to height
        setH = ah;
        setW = ah * childAspect;
      } else {
        // Frame is more landscape
        // Fit to width
        setW = aw;
        setH = aw * childReverseAspect;
      }

      setW = Math.round(setW);
      setH = Math.round(setH);

      setSize([setW, setH]);
    } else {
      // Contain mode...

      let setW, setH;
      if (aspect > childAspect) {
        // Frame is more landscape
        // Fit to height
        setH = ah;
        setW = ah * childAspect;
      } else {
        // Frame is more portrait
        // Fit to width
        setW = aw;
        setH = aw * childReverseAspect;
      }

      setW = Math.round(setW);
      setH = Math.round(setH);

      setSize([setW, setH]);
    }
  }

  useEffect(() => {
    // Add event listener
    window.addEventListener("resize", resize);
    // Call handler right away so state gets updated with initial window size
    resize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", resize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div
      ref={ref}
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        overflow: "hidden",
      }}
    >
      <div
        ref={child}
        style={{
          position: "absolute",
          top: `50%`,
          left: `50%`,
          transform: `translate(-50%,-50%)`,
          width: size[0],
          height: size[1],
        }}
      >
        {children}
      </div>
    </div>
  );
}

export const Gallery = ({ ix }) => {
  let history = useHistory();
  const completed = useRef(false);
  const ref = useRef();
  const { scrollY } = useElementScroll(ref);
  const [opacity, setOpacity] = useState(1);

  const screenRef = useRef();
  const [blackout, setBlackout] = useState(1);

  //const ACTIVE_TILE = useStore(state => state.ACTIVE_TILE);

  const HISTORY = useStore((state) => state.HISTORY);
  const HOME = useStore((state) => state.HOME);

  let previousListingPage = HISTORY.find(
    (e) => ["project"].indexOf(e.type) === -1
  );
  let backTo = previousListingPage ? previousListingPage.href : "/";

  let slice = HOME.body[ix];

  scrollY.onChange(() => {
    // Heading disappears after 200px scroll
    let o = 1 - scrollY.current / 200;
    if (o < 0) o = 0;

    if (o !== opacity) setOpacity(o);

    let maxScroll = ref.current.scrollHeight - ref.current.clientHeight;
    let startAt = maxScroll - screenRef.current.offsetHeight;

    startAt += 200;

    let dist = maxScroll - startAt;

    if (scrollY.current >= startAt) {
      let blackoutProgress = (scrollY.current - startAt) / dist;
      let b = 1 - blackoutProgress;

      if (b !== blackout) setBlackout(b);

      // On complete...
      if (blackoutProgress >= 0.98 && !completed.current) {
        // Update the ref
        completed.current = true;
        // Go back a page
        history.push(backTo);
      }
    }
  });

  return (
    <div className="Page Page--project">
      <BodyClass className={`Body--gallery`} />

      <Link className="Page-close" to={backTo}>
        <GrFormClose />
      </Link>

      <div className="Page-description">
        <motion.div
          style={{ opacity: opacity, translateY: -10 * (1 - opacity) }}
        >
          <p>{slice.primary.heading}</p>
        </motion.div>
      </div>

      <div className="Page-scroll" ref={ref}>
        <div className="Page-paper">
          <div className="Page-blocks Page-blocks--tiles">
            <Masonry
              breakpointCols={3}
              className="Page-masonry-grid"
              columnClassName="Page-masonry-column"
            >
              {slice.items.map((item) => (
                <div
                  className="Page-masonry-tile"
                  style={{ background: slice.primary.background }}
                >
                  <Media item={item} />
                </div>
              ))}
            </Masonry>

            {/*
            <ul className="Page-tiles">
            {slice.items.map(item => <li><div className="Page-tile" style={{background:slice.primary.background}}><Media item={item} /></div></li>)}
            </ul>
          */}
          </div>
        </div>
        <div
          className="Page-blackout"
          style={{ opacity: blackout }}
          ref={screenRef}
        />
      </div>
    </div>
  );
};
