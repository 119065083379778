import React, { useEffect, useRef, useState } from "react";
import IsVisible from "./IsVisible";
import { motion } from "framer-motion";

export const meta = {
  settings: {},
  defaultProps: {},
};

const LoadImage = ({ src = null, fit = "cover" }) => {
  const currentSrc = useRef(false);
  const alreadyLoaded = src === currentSrc.current;
  const [loaded, setLoaded] = useState(alreadyLoaded);
  useEffect(() => {
    currentSrc.current = src;
    const i = new Image();
    i.onload = () => {
      setLoaded(true);
    };
    i.src = src;
  }, [src]);

  if (!src) return null;

  if (!loaded) return null;
  return (
    <motion.img
      key={src}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.1 }}
      src={src}
    />
  );
};

const LazyImage = ({ src = null, fit = "cover" }) => {
  return (
    <IsVisible
      once={() => <LoadImage src={src} />}
      fallback={() => <div>Loading...</div>}
    />
  );
};

export default LazyImage;
