import React from 'react';
import ReactDOM from 'react-dom';

//import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import ReactBreakpoints from 'react-breakpoints';

import App from './App';

const breakpoints = {
  mobile: 650,
  tablet: 745,
  desktop: 1024,
  desktopWide: 1400,
  desktopMax: 1920
}

ReactDOM.render(
  <React.StrictMode>
  <BrowserRouter>
  <ReactBreakpoints
    breakpoints={breakpoints}
    debounceResize={true}
    defaultBreakpoint={breakpoints.mobile}
  >
    <App />
  </ReactBreakpoints>
  </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
