import { motion, AnimatePresence } from "framer-motion"

const Presence = ({ className='',isVisible,children }) => (
  <AnimatePresence>
    {isVisible && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={className}
      >
      {children}
      </motion.div>
    )}
  </AnimatePresence>)

  export {Presence};