import React, { useEffect, useState, useRef } from "react";
import { useStore, Store, StoreRouter } from "../store";
import { Content } from "../components/Content.js";
import LazyImage from "../components/LazyImage.js";
import { NavLink, Link, useHistory, useLocation } from "react-router-dom";

import { motion, useElementScroll } from "framer-motion";

export const Profile = ({ entry }) => {
      let history = useHistory();
      const completed = useRef(false);
      const ref = useRef();
      const { scrollY } = useElementScroll(ref);
      const [opacity, setOpacity] = useState(1);

      const screenRef = useRef();
      const [blackout, setBlackout] = useState(1);

      //const ACTIVE_TILE = useStore(state => state.ACTIVE_TILE);

      const HISTORY = useStore((state) => state.HISTORY);
      const HOME = useStore((state) => state.HOME);

      let previousListingPage = HISTORY.find(
            (e) => ["project"].indexOf(e.type) === -1
      );
      let backTo = previousListingPage ? previousListingPage.href : "/";

      scrollY.onChange(() => {
            // Heading disappears after 200px scroll
            let o = 1 - scrollY.current / 200;
            if (o < 0) o = 0;

            if (o !== opacity) setOpacity(o);

            let maxScroll = ref.current.scrollHeight - ref.current.clientHeight;
            let startAt = maxScroll - screenRef.current.offsetHeight;

            startAt += 200;

            let dist = maxScroll - startAt;

            if (scrollY.current >= startAt) {
                  let blackoutProgress = (scrollY.current - startAt) / dist;
                  let b = 1 - blackoutProgress;

                  if (b !== blackout) setBlackout(b);

                  // On complete...
                  if (blackoutProgress >= 0.98 && !completed.current) {
                        // Update the ref
                        completed.current = true;
                        // Go back a page
                        history.push(backTo);
                  }
            }
      });

      return (
            <div className="Page Page--profile">
                  <div className="Page-scroll" ref={ref}>
                        <div className="Page-bg">
                              <div className="Page-paper">
                                    <h1 className="Page-title">
                                          {entry.title}
                                    </h1>

                                    <div className="Profile">
                                          <div className="Profile-intro">
                                                <Content content={entry.body} />
                                          </div>
                                          <div className="Profile-contact">
                                                <Content
                                                      modifier="contact"
                                                      content={entry.contact}
                                                />
                                          </div>
                                          <div className="Profile-aside">
                                                <div>
                                                      <Content
                                                            content={
                                                                  entry.section_1
                                                            }
                                                      />
                                                </div>
                                                <div>
                                                      <Content
                                                            content={
                                                                  entry.section_2
                                                            }
                                                      />
                                                </div>
                                          </div>
                                          <h3 className="Page-scroll-title">
                                                Index
                                          </h3>
                                    </div>
                              </div>

                              <div className="Page-horiz-scroll">
                                    {HOME.body.map((slice, s_ix) => {
                                          if (slice.slice_type !== "carousel")
                                                return null;
                                          if (slice.slice_type !== "carousel")
                                                return null;
                                          if (!slice.items.length) return null;
                                          if (
                                                !slice.items[0].hasOwnProperty(
                                                      "media"
                                                )
                                          )
                                                return null;
                                          if (
                                                !slice.items[0].media.hasOwnProperty(
                                                      "url"
                                                )
                                          )
                                                return null;

                                          return (
                                                <div className="Page-thumb">
                                                      <Link
                                                            to={`#/gallery/${
                                                                  s_ix + 1
                                                            }/`}
                                                      >
                                                            <LazyImage
                                                                  src={`${slice.items[0].media.url}?w=800`}
                                                            />
                                                            <h2>
                                                                  {
                                                                        slice
                                                                              .primary
                                                                              .heading
                                                                  }
                                                                  &nbsp;
                                                            </h2>
                                                      </Link>
                                                </div>
                                          );
                                    })}
                              </div>
                        </div>
                        <div
                              className="Page-blackout"
                              style={{ opacity: blackout }}
                              ref={screenRef}
                        />
                  </div>
            </div>
      );
};
