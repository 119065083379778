import React, { useEffect, useState, useRef, memo } from "react";
import { Helmet } from "react-helmet";
import { NavLink, Link, useHistory, useLocation } from "react-router-dom";
import { DragWall } from "../components/DragWall.js";
import { shuffle } from "../utils/shuffle.js";
import { useStore, Store, StoreRouter } from "../store";
import { Content } from "../components/Content.js";
import IsVisible from "../components/IsVisible.js";
import { Presence } from "../components/Presence.js";
import { FadeSwiper } from "../components/FadeSwiper.js";
import { ScrollOpacity } from "../components/ScrollOpacity.js";
import { GrFormView } from "react-icons/gr";

import { useMount, useWindowSize } from "react-use";
import { get } from "../utils/ajax";
import anime from "animejs";

import { BsGrid3X3, BsCollectionPlay, BsCaretRight } from "react-icons/bs";
import {
  GrContract,
  GrFormPreviousLink,
  GrClose,
  GrUndo,
  GrFormClose,
} from "react-icons/gr";
import {
  CgMenuGridR,
  CgMicrosoft,
  CgPlayButtonO,
  CgRename,
} from "react-icons/cg";
import {
  IoChevronDownCircleOutline,
  IoPlayCircleOutline,
} from "react-icons/io5";
import { IoMdPlay } from "react-icons/io";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.min.css";
import "swiper/components/effect-fade/effect-fade.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";

// import Swiper core and required modules
import SwiperCore, {
  EffectFade,
  Navigation,
  Pagination,
  Mousewheel,
} from "swiper/core";

function Media({ item, fit = "cover" }) {
  let url = "";
  if (item.media && item.media.url && item.media.url.length) {
    url = item.media.url;
  } else if (item.video_url) {
    // Set url to video url (could be remote mp4 or vimeo embed)
    url = item.video_url;
  }

  if (url.match(".mp4")) {
    return (
      <video
        src={url}
        initial={"false"}
        autoPlay={true}
        controls={false}
        playsInline={true}
        muted={true}
        loop={true}
      />
    );
  } else if (item.video_url) {
    // Video URL that's not an mp4, embed it...
    return <VideoIframe id={item.video_url} fit={fit} />;
  } else {
    return <img src={`${url}&w=700`} />;
  }
}

function VimeoSize({ preload, id, children }) {
  let [size, setSize] = useState(null);

  useMount(() => {
    //console.log("get size", id);
    get(
      `https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/${id}`,
      {},
      (response) => {
        if (response.hasOwnProperty("width")) {
          setSize([parseInt(response.width), parseInt(response.height)]);
        }
      }
    );
  });

  if (!size)
    return (
      <Helmet>
        <link rel="prefetch" href={preload} />
      </Helmet>
    );

  return children({ width: size[0], height: size[1] });
}

const VideoIframe = memo(({ id, fit }) => {
  // eg 274772955
  if (!id) return null;

  let vimeoId = parseInt(id.replace(/https?:\/\/vimeo.com\//, ""));

  return (
    <VimeoSize
      preload={`https://player.vimeo.com/video/${vimeoId}?title=0&byline=0&portrait=0&color=000000&muted=1&autoplay=1&autopause=0&loop=1&background=1&app_id=122963`}
      key={`videoiframe_${vimeoId}`}
      id={vimeoId}
    >
      {({ width, height }) => (
        <div data-rel="iframe">
          <div className="Iframe-loader" />
          <FitBox key={`fitbox_${vimeoId}`} w={width} h={height} mode={fit}>
            <iframe
              key={`iframe_${vimeoId}`}
              src={`https://player.vimeo.com/video/${vimeoId}?title=0&byline=0&portrait=0&color=000000&muted=1&autoplay=1&autopause=0&loop=1&background=1&app_id=122963`}
              width={"100%"}
              height={"100%"}
              frameBorder={0}
              allow={"autoplay"}
              allowFullScreen
            ></iframe>
          </FitBox>
        </div>
      )}
    </VimeoSize>
  );
});

const Iframe = memo((props) => {
  return <iframe {...props}></iframe>;
});

function FitBox({ w = 1, h = 1, mode = "cover", children }) {
  let ref = useRef(null);
  let child = useRef(null);
  let [size, setSize] = useState(["100%", "auto"]);

  function resize() {
    // Size & aspect of frame (space to fill)
    let aw = ref.current.offsetWidth;
    let ah = ref.current.offsetHeight;
    let aspect = aw / ah;
    let reverseAspect = ah / aw;

    // Size and aspect of child (object ot fitted)
    let cw = w;
    let ch = h;
    let childAspect = cw / ch;
    let childReverseAspect = ch / cw;

    if (mode === "cover") {
      // Cover mode
      let setW, setH;
      if (aspect < childAspect) {
        // Frame is more portrait
        // Fit to height
        setH = ah;
        setW = ah * childAspect;
      } else {
        // Frame is more landscape
        // Fit to width
        setW = aw;
        setH = aw * childReverseAspect;
      }

      setW = Math.round(setW);
      setH = Math.round(setH);

      setSize([setW, setH]);
    } else {
      // Contain mode...

      let setW, setH;
      if (aspect > childAspect) {
        // Frame is more landscape
        // Fit to height
        setH = ah;
        setW = ah * childAspect;
      } else {
        // Frame is more portrait
        // Fit to width
        setW = aw;
        setH = aw * childReverseAspect;
      }

      setW = Math.round(setW);
      setH = Math.round(setH);

      setSize([setW, setH]);
    }
  }

  useEffect(() => {
    // Add event listener
    window.addEventListener("resize", resize);
    // Call handler right away so state gets updated with initial window size
    resize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", resize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <div
      ref={ref}
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        overflow: "hidden",
      }}
    >
      <div
        ref={child}
        style={{
          position: "absolute",
          top: `50%`,
          left: `50%`,
          transform: `translate(-50%,-50%)`,
          width: size[0],
          height: size[1],
        }}
      >
        {children}
      </div>
    </div>
  );
}

function Break({ slice }) {
  return (
    <>
      <div className="Slice--window">
        <div className="Slice Slice--break">
          <div className={"Slice-media"}>
            <div
              className={`Slice-media-frame Slice-media-frame--${slice.primary.size.toLowerCase()}`}
            >
              <Media
                fit={slice.primary.size.toLowerCase()}
                item={{
                  media: slice.primary.media,
                  video_url: slice.primary.video_url,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const InstaStories = ({ page, profile }) => {
  const swiperApi = useRef(null);
  const [isEnlarged, setEnlarged] = useState(0);
  const [curSlide, setCurSlide] = useState(0);

  function isActiveOrNeighbour(ix) {
    const isActive = curSlide === ix;
    const isPrev = curSlide === ix - 1;
    const isNext = curSlide === ix + 1;

    return isActive || isPrev || isNext;
  }

  return (
    <div
      onClick={() => {
        if (!isEnlarged) {
          setEnlarged(1);
          setTimeout(() => swiperApi.current.update(), 1);
        }
      }}
    >
      <Presence isVisible={isEnlarged}>
        <div
          className="Page-close Page-close--stories"
          onClick={() => {
            setEnlarged(0);
            setTimeout(() => swiperApi.current.update(), 1);
          }}
        >
          <GrFormClose />
        </div>
      </Presence>

      <Swiper
        onSwiper={(api) => {
          api.on("slideChange", function () {
            setCurSlide(api.realIndex);
          });
          swiperApi.current = api;
        }}
        slidesPerView={"auto"}
        centeredSlides={true}
        loop={false}
        spaceBetween={10}
        modules={[Pagination]}
        className={`StorySwiper StorySwiper--${
          isEnlarged ? "maximised" : "minimsed"
        }`}
      >
        <SwiperSlide key={`slide_reel`}>
          <div className="Story" key={`story_reel`}>
            <LazySlideContents
              key={`lazy_reel`}
              active={isActiveOrNeighbour(0)}
            >
              <TapSwiper
                isEnlarged={isEnlarged}
                onFirstClick={() => {
                  if (isEnlarged) swiperApi.current.slidePrev();
                }}
                onLastClick={() => {
                  if (isEnlarged) swiperApi.current.slideNext();
                }}
                slides={[
                  {
                    content: () => (
                      <div
                        style={{ background: "black" }}
                        className={`Story-object Story-object--cover`}
                      >
                        <Media item={{ fit: "cover", media: page.reel }} />
                      </div>
                    ),
                  },
                ]}
              />
            </LazySlideContents>
          </div>
        </SwiperSlide>

        {page.body.map((slice, s_ix) => {
          let storyContent = [];
          let caption = null;
          switch (slice.slice_type) {
            case "carousel":
              caption = (
                <>
                  <h2>{slice.primary.heading}</h2>
                  <Content content={slice.primary.description} />
                </>
              );
              storyContent = slice.items.map((item, ix) => {
                return {
                  isPaused: true,

                  content: () => (
                    <div
                      style={{ background: slice.primary.background }}
                      className={`Story-object Story-object--${item.size.toLowerCase()}`}
                    >
                      <Media
                        key={ix}
                        item={item}
                        fit={item.size.toLowerCase()}
                      />
                    </div>
                  ),
                };
              });
              break;

            // video_break
            case "video_break":
              storyContent = [
                {
                  isPaused: true,
                  content: () => (
                    <div
                      style={{ background: slice.primary.background }}
                      className={`Story-object Story-object--${slice.primary.size.toLowerCase()}`}
                    >
                      <Media
                        fit={slice.primary.size.toLowerCase()}
                        item={{
                          media: slice.primary.media,
                          video_url: slice.primary.video_url,
                        }}
                      />
                    </div>
                  ),
                },
              ];
              break;
          }

          return (
            <SwiperSlide key={`slide_${s_ix}`}>
              <div className="Story" key={`story_${s_ix}`}>
                <LazySlideContents
                  key={`lazy_${s_ix}`}
                  active={isActiveOrNeighbour(s_ix + 1)}
                >
                  <TapSwiper
                    isEnlarged={isEnlarged}
                    caption={caption}
                    slides={storyContent}
                    onFirstClick={() => {
                      if (isEnlarged) swiperApi.current.slidePrev();
                    }}
                    onLastClick={() => {
                      if (isEnlarged) swiperApi.current.slideNext();
                    }}
                  />
                </LazySlideContents>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

function TapSwiper({
  isEnlarged,
  slides,
  caption,
  onLastClick = () => {},
  onFirstClick = () => {},
}) {
  const [curSlide, setCurSlide] = useState(0);
  const swiper = useRef(null);
  function isActiveOrNeighbour(ix) {
    const isActive = curSlide === ix;
    const isPrev = curSlide === ix - 1;
    const isNext = curSlide === ix + 1;

    return isActive || isPrev || isNext;
  }

  function isActive(ix) {
    const isActive = curSlide === ix;

    return isActive;
  }
  function prevClick() {
    if (!isEnlarged) return;
    if (swiper.current) {
      if (swiper.current.realIndex === 0) {
        onFirstClick();
      } else {
        swiper.current.slidePrev();
      }
    }
  }
  function nextClick() {
    if (!isEnlarged) return;
    if (swiper.current) {
      if (swiper.current.realIndex === slides.length - 1) {
        onLastClick();
      } else {
        swiper.current.slideNext();
      }
    }
  }

  useEffect(() => {
    setTimeout(() => swiper.current.update(), 1);
  }, [isEnlarged]);
  return (
    <>
      <Swiper
        onSwiper={(api) => {
          api.on("slideChange", function () {
            setCurSlide(api.realIndex);
          });
          swiper.current = api;
        }}
        slidesPerView={"auto"}
        centeredSlides={true}
        loop={false}
        spaceBetween={0}
        pagination={{
          clickable: false,
        }}
        allowTouchMove={false}
        effect={"fade"}
        speed={500}
        modules={[EffectFade, Pagination]}
        className={`TapSwiper TapSwiper--${slides.length}`}
      >
        {slides.map((slide, s_ix) => (
          <SwiperSlide key={`tap_slide_${s_ix}`}>
            <LazySlideContents
              key={`lazy_${s_ix}`}
              active={isEnlarged ? isActiveOrNeighbour(s_ix) : isActive(s_ix)}
            >
              {slide.content()}
            </LazySlideContents>
          </SwiperSlide>
        ))}
      </Swiper>
      {(() => {
        if (caption) {
          return (
            <div>
              <div className="Story-caption">{caption}</div>
            </div>
          );
        }
      })()}

      <div
        style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
      >
        <div
          onClick={prevClick}
          style={{
            zIndex: 2,
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            width: "20%",

            opacity: 0,
          }}
        />
        <div
          onClick={nextClick}
          style={{
            zIndex: 2,
            position: "absolute",
            top: 0,
            left: "20%",
            bottom: 0,
            width: "80%",

            opacity: 0,
          }}
        />
      </div>
    </>
  );
}
const LazySlideContents = memo(({ active, children }) => {
  return active ? children : null;
});
