import { RichText } from "prismic-reactjs";
import { useHistory } from "react-router-dom";
export const linkResolver = (doc) => {
  // URL for a category type
  if (doc.type === "category") {
    return `/category/${doc.uid}`;
  }

  // URL for a product type
  if (doc.type === "product") {
    return `/product/${doc.uid}`;
  }

  // URL for a page type
  if (doc.type === "page") {
    return `/${doc.uid}`;
  }

  // Backup for all other types
  return "/";
};

export const Content = ({ content, modifier = "default" }) => {
  let history = useHistory();

  let noWidows = content.map((item) => {
    return item.hasOwnProperty("text") ? { ...item, text: item.text } : item;
  });

  const navigate = (event) => {
    let target =
      event.target.tagName === "IMG"
        ? event.target.parentElement
        : event.target;

    if (target.tagName === "A") {
      // Internal link?
      let href = target.getAttribute("href");
      if (!href) return;
      let hasExtension = href.indexOf(".") != -1;
      let internalLink = href.indexOf("/") == 0;
      let isHash = href.indexOf("#") == 0;
      let isEmail = href.indexOf("@") != -1;
      let isJavascript = href.indexOf("javascript:") != -1;
      let isTel = href.indexOf("tel:") != -1;

      let newWindow = !isHash && !isTel && !isEmail && !isJavascript;

      let useRouter = (internalLink || isHash) && !hasExtension;
      if (useRouter) {
        event.preventDefault();
        history.push(target.getAttribute("href"));
      } else if (newWindow) {
        event.preventDefault();
        // Add noopener attribute https://dev.to/ben/the-targetblank-vulnerability-by-example
        window.open(target.getAttribute("href"), "_blank", "noopener");
      }
    }
  };

  return (
    <div className={`Content Content--${modifier}`} onClick={navigate}>
      <RichText render={noWidows} linkResolver={linkResolver} />
    </div>
  );
};
