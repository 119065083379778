export function uriMatch(string,pattern){


    // Replace .* for a non slash search. Allows for quick patterns like /segment/(.*)/(.*)
    let route = pattern.replace(/\.\*/gi,'[^/]*');

    // Replace .+ for a non slash search. Allows for quick patterns like /segment/(.+)/(.+)
    route = route.replace(/\.\+/gi,'[^/]+');
        
    let match;

    let regExp = new RegExp(route,"i");
    match = regExp.exec(string);
    return match;
}