import { useBodyClass } from "../hooks/useBodyClass";

// TODO make NPM package so it's not dependent on this codebase

export function isTouchDevice() {
	return (
		"ontouchstart" in window ||
		navigator.maxTouchPoints > 0 ||
		navigator.msMaxTouchPoints > 0
	);
}

export const isTouch = isTouchDevice();

export const IsTouch = ({ children }) => {
	return isTouch && children;
};

export const NotTouch = ({ children }) => {
	return !isTouch && children;
};

export const TouchClass = ({ children }) => {
	let className = isTouch ? "is-touch" : "no-touch";

	useBodyClass(className);

	return children;
};
