import React , {useEffect,useState,useRef} from 'react'
import create from 'zustand';
import {transformer} from './transformer';
import {uriMatch} from '../utils/uriMatch';
import {  NavLink, Link, useHistory, useLocation} from 'react-router-dom';


/*

Basic store properties. Custom values loaded via transformer

*/

const useStore = create((set,get) => ({
  cursorState : 'default',
  cursorActive : false,
  setCursor: (state) => {
    return set({ cursorState: state, cursorActive : true})
  },
  unsetCursor: () => {
    return set({ cursorActive : false})
  },
  loaded :false, // Loaded from API
  HISTORY : [], // Pages visited
  PREV_ENTRY : null, // Previous entry
  ENTRY : null, // Current entry
  HREF : null, // Pathname & hash
  PREV_HREF : null, // Previous pathname & hash
  ENTRIES : [], // All entries (tidied data)
  data : [], // Raw data

  setDataLoaded: (data) => {
    let transformed = transformer(data,set,get);
    
    return set({ loaded: true, data : data, ...transformed })
  },

  setRouteData: ({routes,location}) => (set(state => {


    const href = location.pathname + location.hash;

    let matched = 0;

    const time = new Date();
    let data = {TIMESTAMP:time};

    routes.forEach((r,r_ix)=>{

      let match = uriMatch(href,r.route);

      if(match){

        let d;
        matched = 1; // We have something to store...

        if(typeof r.data == 'function'){
          d = r.data(match,state);

          // Add the updated keys to the temp data object
          for(let k in d){
              if(d.hasOwnProperty(k)){
                data[k] = d[k];
              }
            }
          }
      }

  })

  return data;


  })),

  setActiveEntry: (pathname,hash='') => (set(state => {

      let h = state.HISTORY;
      if(state.ENTRY){
        h.unshift({
          ...state.ENTRY,
          href : state.HREF
        });
      }
      
      return { HISTORY: h ,PREV_HREF: state.HREF,PREV_ENTRY: state.ENTRY, HREF:`${pathname}${hash}`,ENTRY: state.ENTRIES.find(e => e.uri === pathname) }
    })),

  getEntryById: (id) => {

    const found = get().ENTRIES.find(e=>e.id === id);
    return found;

  }

}));


/*

Store provider component
Runs children as function with all store properties passed to it...

*/

const Store = ({children}) => {


  const state = useStore();

  return children(state);


}

/*

StoreRouter component
Watches history and updates store ENTRY variable when location changes...

*/

const StoreRouter = ({children,routes=[]}) => {


  const ENTRY = useStore(state => state.ENTRY);
  const setActiveEntry = useStore(state => state.setActiveEntry);
  const setRouteData = useStore(state => state.setRouteData);
  const history = useHistory();
  const location = useLocation();
  

  useEffect(() => {
    
    setActiveEntry(location.pathname,location.hash);
    setRouteData({routes,location});

    history.listen((args)=>{
      setActiveEntry(args.pathname,args.hash);
      setRouteData({routes,location:args});
    })
  }, [history]);

  return children;


}



export {useStore,Store,StoreRouter}