import React from "react";
import { Link } from "react-router-dom";
//import {  motion } from "framer-motion";
//import { Media } from 'react-breakpoints';
import { Helmet } from "react-helmet";

/*

Local

*/

import { PrismicAPI } from "./components/Prismic.js";
import { TouchClass } from "./components/IsTouch.js";
import { Presence } from "./components/Presence.js";
import { Burger } from "./components/Burger.js";
import { Nav } from "./components/Nav.js";
import { SmartCursor } from "./components/SmartCursor.js";
import { RespondTo } from "./components/RespondTo.js";
import { useBodyClass } from "./hooks/useBodyClass.js";

/*

Store

*/

import { useStore, Store, StoreRouter } from "./store";
import { routes } from "./store/transformer.js";

/*

Pages

*/

import { Project } from "./pages/Project.js";
import { Gallery } from "./pages/Gallery.js";
import { Home } from "./pages/Home.js";
import { Profile } from "./pages/Profile.js";
import { Index } from "./pages/Index.js";
import { InstaStories as Stories } from "./pages/Stories.js";

/*

Styles

*/

import "./App.scss";

/*

Assets

*/

import { breakpoints } from "./data/breakpoints.js";

function BodyClass({ className }) {
  useBodyClass(className);

  return <div />;
}

function Meta({ data }) {
  if (!data) return null;

  let matchKey = Object.keys(data)
    .filter((k) => window.location.href.match(k))
    .pop();
  if (!matchKey) return null;

  let metaData = data[matchKey];

  return (
    <Helmet>
      <title>{metaData.title}</title>
      <meta name="description" content={metaData.description} />
    </Helmet>
  );
}

function App() {
  const setDataLoaded = useStore((state) => state.setDataLoaded);
  const isDataLoaded = useStore((state) => state.loaded);

  return (
    <TouchClass>
      <PrismicAPI
        endpoint={`https://kucharswara.cdn.prismic.io/api/v2`}
        loader={() => <div />}
        onLoad={({ docs }) => {
          // Update the store
          setDataLoaded(docs);
        }}
      >
        {({ isLoaded, docs }) => {
          if (!isDataLoaded) return <BodyClass className={`Body--loading`} />;
          else
            return (
              <StoreRouter routes={routes}>
                <Store>
                  {({
                    cursorState,
                    cursorActive,
                    GALLERY_IX,
                    SHOWGALLERY,
                    HOME,
                    MOBILE,
                    INDEX,
                    PREV_ENTRY,
                    ENTRY,
                    ENTRIES,
                    PROFILE,
                    TILES,
                    PROJECT_INDEX,
                    getEntryById,
                  }) => {
                    if (!ENTRY) return null;

                    let showIndex =
                      ENTRY.type === "index" ||
                      (PREV_ENTRY && PREV_ENTRY.type === "index");
                    if (ENTRY.id === HOME.id) showIndex = false;

                    return (
                      <>
                        <div className="Site-body">
                          <Meta page={ENTRY} />

                          <BodyClass className={`Body--${ENTRY.type}`} />

                          <Presence isVisible={ENTRY.type !== "project"}>
                            <h1 className="Site-title">
                              <Link to={"/"}>Kuchar Swara</Link>
                            </h1>

                            <Burger />
                          </Presence>

                          <RespondTo media={breakpoints["mobile"]}>
                            <Stories page={MOBILE} profile={PROFILE} />

                            <Presence
                              className="Layer Layer--profile"
                              isVisible={ENTRY.type === "profile"}
                            >
                              <Profile entry={PROFILE} />
                            </Presence>
                          </RespondTo>
                          <RespondTo media={breakpoints["tablet-up"]}>
                            <Presence
                              className="Layer Layer--project"
                              isVisible={SHOWGALLERY}
                            >
                              <Gallery ix={GALLERY_IX - 1} />
                            </Presence>

                            <Presence
                              className="Layer Layer--profile"
                              isVisible={ENTRY.type === "profile"}
                            >
                              <Profile entry={PROFILE} />
                            </Presence>

                            <Presence
                              className="Layer Layer--index"
                              isVisible={showIndex}
                            >
                              <Index entry={INDEX} projects={PROJECT_INDEX} />
                            </Presence>

                            <Presence
                              className="Layer Layer--tiles"
                              isVisible={true}
                            >
                              <Home page={HOME} profile={PROFILE} />
                            </Presence>

                            <SmartCursor
                              state={cursorState}
                              active={cursorActive}
                            />
                          </RespondTo>
                        </div>
                      </>
                    );
                  }}
                </Store>
              </StoreRouter>
            );
        }}
      </PrismicAPI>
    </TouchClass>
  );
}

export default App;
